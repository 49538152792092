import { useAppSelector } from 'core/store/hooks';
import { useMemo } from 'react';
import { getStorageValue } from 'utils';

export const useUnstoppableName = (): string | undefined => {
  const user = useAppSelector((state) => state.auth.user);

  return useMemo(() => {
    if (!user) return undefined;
    const providerName = getStorageValue<string>(
      'WEB3_CONNECT_CACHED_PROVIDER'
    );
    const uauthName =
      getStorageValue<{ expiresAt: number; value: string }>('username');

    if (
      providerName &&
      providerName === 'uauth' &&
      uauthName &&
      uauthName.expiresAt > Date.now()
    ) {
      return uauthName.value;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};
